<template>
	<el-card class="statCard">
		<div style="width: 100%;  " class="font-semibold text-lg flex">
			<el-divider direction="vertical" style="color: #7d89c3;width: 10px;"></el-divider>
			<span class="mr-20">{{topTitle}}</span>
		</div>
		<!-- <div class="yunaqu" style="margin-top: 1rem;">
			<el-descriptions class="margin-top" title="" style="width: 100%;" :column="1" size="small" border>
				<el-descriptions-item>
					<template slot="label">
						请选择园区
					</template>
					<el-select v-model="nursery" size="small" style="width:100%" placeholder="请选择园区"
						@change="selectNursery">
						<el-option v-for="item in options" :key="item.id" :label="item.nursery" :value="item">
						</el-option>
					</el-select>
				</el-descriptions-item>
			</el-descriptions>
		</div> -->
		<div class="date" style="position: relative;z-index: 1000;">
			<el-date-picker v-model="time" type="date" size="small" style="width:13rem" format="yyyy-MM-dd"
				value-format="yyyy-MM-dd" class="datePicker" placeholder="请选择日期" @change="changeDate">
			</el-date-picker>
		</div>
		<div class="date2" style="margin-top: 0.5rem;">
			<el-descriptions class="margin-top" style="width: 100%;" :column="1" size="small" border>
				<el-descriptions-item>
					<template slot="label">
						请选择日期
					</template>
					<el-date-picker v-model="time" type="date" size="small" style="width:100%" format="yyyy-MM-dd"
						value-format="yyyy-MM-dd" class="datePicker" placeholder="请选择日期" @change="changeDate">
					</el-date-picker>
				</el-descriptions-item>
			</el-descriptions>
		</div>

		<div class="isDisplay">
			<el-scrollbar >
				<div style="display: flex;padding-bottom: 20px;">
					<el-card class="schoolDetail mid" style="display: flex;width: fit-content;"
						:style="{border:isAll?'2px solid #7d89c3':'',background:isAll?'#7d89c3':'',color:isAll?'white':''}">
						<div @click="checkClick(0,0,'allregion')">
							<div style="font-size: 0.8rem;"><span :style="{color:isAll?'white':''}">集团数量</span><span
									class="numTitle" >{{all.school_num}}</span></div>
							<div style="margin-top: 0.5rem;font-size: 0.8rem;" ><span :style="{color:isAll?'white':''}">学生数量</span><span
									class="numTitle">{{all.child_num}}</span></div>
							<div style="margin-top: 0.5rem;font-size: 0.8rem;"><span :style="{color:isAll?'white':''}">家长数量</span><span
									class="numTitle">{{all.parent_num}}</span></div>
						</div>
					</el-card>
					<el-card class="schoolDetail mid" :style="{border:index==checkIndex?'2px solid #7d89c3':'',
				background:index==checkIndex?'#7d89c3':'',color:index==checkIndex?'white':''}"
						style="display: flex;width: fit-content;" v-for="(item,index) in nurseryData">
						<div @click="checkClick(index,item,'region')">
							<div class="title"><span :style="{color:index==checkIndex?'white':''}">{{item.name}}</span>
							</div>
							<div style="margin-top: 0.8rem;font-size: 0.8rem;"><span>学生数量</span><span
									class="num">{{item.num}}</span></div>
							<div style="margin-top: 0.8rem;font-size: 0.8rem;letter-spacing: 5px;"><span>扫码率</span><span
									class="num">{{item.scan}}</span>
							</div>
						</div>
					</el-card>
				</div>
				<div style="clear: both;"></div>
			</el-scrollbar>
		</div>
		<div class="isShow">
			<el-card class="school" style="height:140px">
				<div><span style="color: rgb(148, 146, 146);">{{phone.nursery}}</span></div>
				<div style="margin-top: 0.5rem;"><span style="color: rgb(148, 146, 146);">班级数量</span><span
						class="numTitle">{{phone.class_num}}</span></div>
				<div style="margin-top: 0.5rem;"><span style="color: rgb(148, 146, 146);">学生数量</span><span
						class="numTitle">{{phone.child_num}}</span></div>
			</el-card>
		</div>
		<div class="displayFlex">
			<div>
				<div class="studentNum">学生总数:{{isAll?all.child_num:studentNum}}</div>
				<div id="pie" class=" pl-6 pie" style="height: 15rem;width: 25rem;">
				</div>
				<div class="number">
					<div>{{pie.in}}</div>
					<div>{{isAll?all.child_num:studentNum-pie.in}}</div>
				</div>
			</div>
			<div>
				<div class="studentNum2">学生总数:{{isAll?all.child_num:studentNum}}</div>
				<div id="pie2" class=" pl-6 pie" style="height: 15rem;width: 25rem;">
				</div>
				<div class="number">
					<div>{{pie.out}}</div>
					<div>{{isAll?all.child_num:studentNum-pie.out}}</div>
				</div>
			</div>
		</div>
	</el-card>

</template>

<script>
	export default {
		data() {
			return {
				date: '',
				isAll: false, //判断是不是总数的统计图
				norNum: 100,
				queNum: 200,
				topTitle: "总数据后台",
				checkIndex: 0,
				checked: [],
				school_id: '',
				nursery_id: '',
				region_id: '',
				options: '', //下拉框
				nursery: '', //园区名字
				all: {
					child_num: '',
					parent_num: '',
					school_num: ''
				},
				phone: '', //移动端数据
				nurseryData: [],
				nurseryIndex: 0, //获取当前园区的索引
				studentNum: '', //学生总数
				time: '',
				nowTime: '',
				firstData: '',
				pie: {
					all: 0,
					in: 0,
					no_in: 0,
					no_out: 0,
					out: 0,
					in_percent: 0,
					no_in_percent: 0,
					out_percent: 0,
					no_out_percent: 0
				}
			}
		},
		mounted() {
			this.drawPie()
			this.drawPie2()
			this.nowDate()
			this.school_id = window.sessionStorage.getItem("school_id")
			this.getList()
			setTimeout(() => {
				this.checkClick(this.checkIndex, this.firstData)
			}, 500)
			setTimeout(() => {
				this.selectNursery(this.options[0])
				this.time = this.nowTime
			}, 600)
		},
		methods: {
			//获取数据
			async getList() {
				const res = await this.http.post("admin/account/regionSummary",{
					time:this.time
				})
				this.all = res.all
				this.nurseryData = res.list
				this.firstData = res.list[0]
				this.options = res.list
				this.region_id = this.firstData.id
				console.log(111)
			},
			//获取下拉框数据
			selectNursery(e) {
				this.studentNum = e.num
				this.getPie('region')
				this.phone = e
			},
			//选择时间
			changeDate(e) {
				if (e == null) {
					this.time = this.nowTime
				} else {
					this.time = e
				}
				this.getList()
				this.getPie('region')

			},
			nowDate() {
				//获取当前时间
				var date = new Date();
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
				if (month < 10) {
					month = "0" + month;
				}
				if (day < 10) {
					day = "0" + day;
				}
				this.nowTime = year + "-" + month + "-" + day;
			},
			checkClick(e, item, type) {
				console.log(item)
				this.studentNum=item.num
				if (type == 'region') {
					this.isAll = false
					if (this.time == null) {
						this.time = this.nowTime
					}
					this.checkIndex = e
					this.region_id = item.id
					this.getPie(type)
				} else if (type == 'allregion') {
					this.isAll = true
					this.checkIndex = -1
					if (this.time == null) {
						this.time = this.nowTime
					}
					this.getPie(type)
				}

			},
			//获取统计图数据
			async getPie(type) {
				var that = this
				if (type == 'region') {
					const res = await this.http.post("admin/account/detail", {
						region_id: this.region_id,
						type: type,
						time: this.time == '' ? this.nowTime : this.time,
					})
					if (res.code == 200) {
						that.pie = res.data
						that.pie.in_percent = Number(that.pie.in / (that.isAll ? that.all.child_num : that
							.studentNum) * 100).toFixed(2)
						that.pie.no_in_percent = Number(((that.isAll ? that.all.child_num : that.studentNum) - that.pie
							.in) / that.studentNum * 100).toFixed(2)
						that.pie.out_percent = Number(that.pie.out / (that.isAll ? that.all.child_num : that
							.studentNum) * 100).toFixed(2)
						that.pie.no_out_percent = Number(((that.isAll ? that.all.child_num : that.studentNum) - that
							.pie.out) / (that.isAll ? that.all.child_num : that.studentNum) * 100).toFixed(
							2)
						this.drawPie()
						this.drawPie2()
					}
				} else if (type == 'allregion') {
					const res = await this.http.post("admin/account/detail", {
						// region_id: this.region_id,
						type: type,
						time: this.time == '' ? this.nowTime : this.time,
					})
					if (res.code == 200) {
						that.pie = res.data
						that.pie.in_percent = Number(that.pie.in / (that.isAll ? that.all.child_num : that
							.studentNum) * 100).toFixed(2)
						that.pie.no_in_percent = Number(((that.isAll ? that.all.child_num : that.studentNum) - that.pie
							.in) / (that.isAll ? that.all.child_num : that.studentNum) * 100).toFixed(2)
						that.pie.out_percent = Number(that.pie.out / (that.isAll ? that.all.child_num : that
							.studentNum) * 100).toFixed(2)
						that.pie.no_out_percent = Number(((that.isAll ? that.all.child_num : that.studentNum) - that
							.pie.out) / (that.isAll ? that.all.child_num : that.studentNum) * 100).toFixed(
							2)
						this.drawPie()
						this.drawPie2()
					}
				}

			},
			drawPie2() {
				var chartDom = document.getElementById('pie2');
				var myChart2 = this.$echarts.init(chartDom);
				var option = {
					tooltip: {
						show: true,
						trigger: 'item'
					},
					legend: {
						top: '45%',
						left: '55%'
					},
					grid: {
						show: false,
						left: '0%',
						bottom: '10%',
						top: '10%',
						right: '0%'
					},
					series: [{
						name: '考勤',
						type: 'pie',
						center: ['30%', '50%'],
						radius: ['60%', '75%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: false,
								fontSize: '20',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: true
						},
						data: [{
								value: this.pie.out,
								name: '正常放学 ' + this.pie.out_percent + '%'
							},
							{
								value: (this.isAll ? this.all.child_num : this.studentNum) - this.pie.out,
								name: '未离校 ' + this.pie.no_out_percent + '%'
							},
						],
						color: [
							'#55bfc0',
							'#3c90f7',
							'#e69d87',
						]
					}]
				}
				myChart2.setOption(option);
				// 自适应
				window.addEventListener("resize", () => {
					myChart2.resize();
				});
			},
			drawPie() {
				var chartDom = document.getElementById('pie');
				var myChart = this.$echarts.init(chartDom);
				var option = {
					tooltip: {
						show: true,
						trigger: 'item'
					},
					legend: {
						top: '45%',
						left: '55%'
					},
					grid: {
						show: false,
						left: '0%',
						bottom: '10%',
						top: '10%',
						right: '0%'
					},
					series: [{
						name: '考勤',
						type: 'pie',
						center: ['30%', '50%'],
						radius: ['60%', '75%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: false,
								fontSize: '20',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: true
						},
						data: [{
								value: this.pie.in,
								name: '正常上学 ' + this.pie.in_percent + '%'
							},
							{
								value: (this.isAll ? this.all.child_num : this.studentNum) - this.pie.in,
								name: '缺勤 ' + this.pie.no_in_percent + '%'
							},
						],
						color: [
							'#55bfc0',
							'#3c90f7',
							'#e69d87',
						]
					}]
				}
				myChart.setOption(option);
				// 自适应
				window.addEventListener("resize", () => {
					myChart.resize();
				});
			}
		},
	}
</script>

<style scoped>
	.el-scrollbar{
		overflow: inherit;
	}
	.sliding_box {
		white-space: nowrap;
		overflow-x: scroll;
	}

	.statCard {
		width: 83vw;
		margin-left: 1rem;
		overflow: hidden;
	}

	.yunaqu {
		display: none;
	}

	.isDisplay {
		display: inherit;

	}

	.isShow {
		display: none;
	}

	.school {
		width: 17vw;
		height: 20vh;
		margin-top: 3rem;
		float: left;
		margin-left: 2rem;
	}

	.schoolDetail {
		width: 17vw;
		height: 17vh;
		margin-top: 3rem;
		margin-left: 2rem;
		min-width: 200px;
	}

	.numTitle {
		padding-left: 2rem;
	}

	.num {
		padding-left: 1rem;
	}

	.studentNum {
		position: relative;
		left: 5rem;
		top: 8rem;
	}

	.studentNum2 {
		position: relative;
		left: 5rem;
		top: 8rem;
	}

	.displayFlex {
		display: flex;
	}

	.date2 {
		display: none;
	}

	.datePicker {
		position: absolute;
		right: 10%;
	}

	.number {
		position: relative;
		left: 23rem;
		bottom: 8.3rem;
		color: #b9b7b7;
		line-height: 190%;
		font-size: 0.8rem;
	}

	.title {
		margin-top: -1rem;
	}

	@media (max-width:48rem) {
		.statCard {
			width: 90vw;
			margin-left: 5vw;
		}

		.title {
			margin-top: 0rem;
		}

		.school {
			width: 75vw;
			height: 22vh;
			margin: auto;
			margin-top: 3rem;
		}

		.number {
			left: 20rem;
		}

		.date {
			display: none;
		}

		.date2 {
			display: inherit;
		}

		/deep/.el-input--mini .el-input__icon {
			display: none;
		}

		.schoolDetail {
			width: 17vw;
			height: 25vh;
			margin-top: 1rem;
			margin-left: 1rem;
			font-size: 1rem;
			/* margin-right: 1rem; */
		}

		.title {
			font-size: 0.8rem;
			/* margin-top: -0.5rem; */
		}

		.numTitle {
			padding-left: 3rem;
		}

		.num {
			padding-left: 1rem;
		}

		.pl-6 {
			left: -1.5rem;
		}

		.pie {
			padding-left: 0rem;
			transform: scale(0.9);
			position: relative;
			left: -18%;
		}

		.studentNum {
			position: relative;
			left: 1rem;
			top: 8rem;
			width: 250px;
		}

		.yunaqu {
			display: inherit;
		}


		.studentNum2 {
			position: relative;
			left: 1.3rem;
			top: 2rem;
		}

		#pie2 {
			padding-left: 0.5rem;
			margin-top: -6rem;
		}

		.displayFlex {
			display: inherit;
		}

		.date {
			padding-top: 3rem;
		}

		.datePicker {
			position: inherit !important;
		}

		.isDisplay {
			display: initial;
		}

		.isShow {
			display: none;
		}
		/deep/.el-input--small .el-input__icon{
			display: none;
		}

	}
</style>
